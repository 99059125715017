import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {yandexMetric} from "./Functions/yandexMetric";
import TgIcon from "../images/tg-icon.png";

export const Header = ({location}) => {

    const [activeClass, setActiveClass] = useState({
        telegram: "",
        header: "",
        fixed: ""
    });

    const handlerScroll = () => {
        if(window.scrollY > 200) {
            setActiveClass({
                telegram: "telegram_hide",
                header: "header_indent",
                fixed: "css-header-fixed"
            })
        } else {
            setActiveClass({
                activeClass: activeClass
            })
        }
    };

    useEffect(() => {

        window.addEventListener('scroll', handlerScroll);

        return () => {
            window.removeEventListener('scroll', handlerScroll)
        }

    });

    const menuLink = [
        { url: "/", name: "Главная", reach: "mainmenu", id: 1 },
        { url: "/scoring/", name: "Скоринг", reach: "scoring", id: 4 },
        { url: "/blog/", name: "Блог", reach: "blog", id: 5 },
    ];

    return (
        <header className={`header-fixed ${activeClass.header ? activeClass.header : ""}`}>
            <div className={`header-fixed ${activeClass.fixed ? activeClass.fixed : ""}`}>
                <div className="container">
                    <div className="header-top">
                        <div className="header-logo">
                            <div className="logo__icon">
                                <Link to="/">
                                    <svg
                                        className="logo__icon_size logo__icon_violet"
                                        width="35"
                                        height="36"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 35 36"
                                        fill="none"
                                    >
                                        <path
                                            d="M26.5996 0.0732855C26.5021 0.00780413 26.3834 -0.0150363 26.2695 0.00978593C26.1557 0.0346082 26.0561 0.105061 25.9926 0.205655L22.9089 5.09252C22.8526 5.18178 22.8286 5.28866 22.8411 5.39439L23.2059 8.48099L20.4003 10.7667C20.309 10.8412 20.25 10.95 20.2364 11.0692C20.2228 11.1885 20.2557 11.3085 20.3278 11.4027C20.3999 11.497 20.5053 11.5578 20.6209 11.5719C20.7365 11.5859 20.8527 11.552 20.9441 11.4776L23.9432 9.03439C24.0023 8.98624 24.0484 8.9232 24.077 8.85134C24.1057 8.77948 24.1159 8.70122 24.1068 8.62408L23.7315 5.44843L26.7278 0.699813C26.7913 0.599177 26.8135 0.476631 26.7894 0.359135C26.7654 0.241638 26.6971 0.138815 26.5996 0.0732855Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M10.6194 9.0345L13.6185 11.4779C13.7098 11.5523 13.8261 11.5862 13.9417 11.5722C14.0572 11.5582 14.1627 11.4973 14.2348 11.4031C14.3069 11.3088 14.3398 11.1888 14.3262 11.0696C14.3126 10.9503 14.2536 10.8415 14.1623 10.7671L11.3567 8.48132L11.7215 5.39472C11.734 5.28899 11.71 5.18211 11.6537 5.09285L8.57018 0.205756C8.50669 0.10511 8.40705 0.0346174 8.29319 0.00978629C8.17933 -0.0150448 8.06057 0.00781931 7.96305 0.0733488C7.86552 0.138878 7.79722 0.241705 7.77315 0.359209C7.74909 0.476713 7.77125 0.599268 7.83475 0.699914L10.831 5.44853L10.4558 8.62418C10.4466 8.70132 10.4569 8.77958 10.4855 8.85144C10.5142 8.92331 10.5603 8.98635 10.6194 9.0345Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M15.6239 7.58451C15.4786 7.58329 15.3346 7.61176 15.2001 7.66827C15.0656 7.72478 14.9432 7.80822 14.8401 7.91378C14.737 8.01934 14.6551 8.14492 14.5993 8.28328C14.5434 8.42165 14.5146 8.57006 14.5146 8.71995C14.5146 8.86984 14.5434 9.01825 14.5993 9.15661C14.6551 9.29498 14.737 9.42056 14.8401 9.52612C14.9432 9.63168 15.0656 9.71512 15.2001 9.77163C15.3346 9.82814 15.4786 9.85661 15.6239 9.85539C15.6345 9.85539 15.6447 9.85403 15.6553 9.85373C15.8379 9.77293 16.0282 9.71171 16.2229 9.67102C16.4219 9.53779 16.5738 9.34172 16.656 9.11207C16.7381 8.88242 16.7461 8.63152 16.6787 8.39682C16.6113 8.16211 16.4722 7.95619 16.2821 7.80979C16.092 7.66339 15.8611 7.58437 15.6239 7.58451Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M31.7552 22.4793C32.7942 22.4185 33.4619 22.1767 33.5882 21.8152C33.7666 21.3046 33.0206 20.3596 32.6511 20.0069C31.5353 18.9706 28.5267 18.2382 26.5338 17.7534C25.8836 17.5953 25.3304 17.4591 24.9431 17.339C23.7611 16.973 21.7418 16.0083 20.7181 15.5061V17.3292C20.713 17.7883 20.6063 18.2402 20.4062 18.6504C21.5832 20.0909 23.8381 21.203 26.9877 21.8751C28.559 22.2059 30.1531 22.4079 31.7552 22.4793Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M9.61905 17.339C9.23147 17.4589 8.68668 17.5956 8.0365 17.7535C6.04321 18.2382 3.03089 18.9706 1.91065 20.0111C1.53076 20.3755 0.794444 21.3033 0.974044 21.8156C1.1007 22.1769 1.76835 22.4185 2.80675 22.4793C3.22921 22.4654 11.3081 22.1472 14.1561 18.6504C13.9559 18.2403 13.8492 17.7884 13.844 17.3292V15.5061C12.8203 16.0083 10.801 16.973 9.61905 17.339Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M10.8825 15.8896C12.054 15.3882 13.3322 14.7617 13.8441 14.5021V12.802C12.3084 12.8203 7.25865 12.8782 6.65191 12.8782C6.64372 12.8782 6.65191 12.8782 6.6435 12.8775C5.63088 12.8296 4.61647 12.8401 3.60499 12.9089C0.904111 13.1077 0.24187 13.7108 0.082453 13.9608C0.0121778 14.0712 -0.0609494 14.2544 0.0819411 14.5923C0.425273 14.8767 2.92052 16.6658 10.8825 15.8896Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M17.1629 6.28125C16.9958 6.28146 16.8331 6.33665 16.6984 6.4388C16.5637 6.54095 16.4641 6.68474 16.4141 6.84929C16.7685 7.00985 17.0699 7.27361 17.2813 7.60826C17.4486 7.34355 17.6728 7.12221 17.9364 6.96143C17.9066 6.77183 17.8124 6.59934 17.6707 6.47475C17.529 6.35017 17.349 6.28159 17.1629 6.28125Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M17.8384 8.71993C17.8386 8.90896 17.8845 9.09495 17.972 9.26101C18.0595 9.42707 18.1859 9.56794 18.3395 9.67082C18.5343 9.71151 18.7245 9.77273 18.9072 9.85352C18.9177 9.85352 18.928 9.85518 18.9386 9.85518C19.1562 9.8552 19.3689 9.78862 19.5499 9.66385C19.7308 9.53909 19.8719 9.36176 19.9551 9.15427C20.0384 8.94679 20.0602 8.71847 20.0178 8.4982C19.9753 8.27794 19.8705 8.07561 19.7167 7.9168C19.5628 7.758 19.3667 7.64985 19.1533 7.60604C18.9398 7.56223 18.7186 7.58473 18.5176 7.67068C18.3165 7.75663 18.1447 7.90218 18.0238 8.08892C17.9029 8.27566 17.8384 8.4952 17.8384 8.71978V8.71993Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M27.9271 12.8775C27.9189 12.8779 27.9187 12.8782 27.9105 12.8782C27.3035 12.8782 22.2539 12.8203 20.7183 12.802V14.5021C21.2302 14.7617 22.5083 15.3882 23.6799 15.8897C31.6187 16.6636 34.1225 14.8871 34.4808 14.5912C34.623 14.254 34.552 14.071 34.4817 13.9607C34.3221 13.7103 33.6606 13.1064 30.9531 12.9083C29.9457 12.8398 28.9355 12.8295 27.9271 12.8775Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M16.804 20.3394C16.6462 20.341 16.4885 20.3295 16.3325 20.3049L16.8517 35.5409V35.5568C16.8517 35.6744 16.897 35.7871 16.9775 35.8702C17.058 35.9533 17.1672 36 17.2811 36C17.395 36 17.5042 35.9533 17.5848 35.8702C17.6653 35.7871 17.7105 35.6744 17.7105 35.5568C17.7105 35.5515 17.7105 35.5463 17.7105 35.5409L18.2297 20.3049C18.0737 20.3295 17.916 20.341 17.7582 20.3394H16.804Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M19.8406 17.3294V12.6318C19.837 12.2389 19.728 11.8548 19.5258 11.5219C19.3237 11.1891 19.0361 10.9205 18.6951 10.7459C18.4671 10.7167 18.2455 10.6482 18.0395 10.5432C17.9462 10.5319 17.8522 10.5271 17.7583 10.529H17.7199V18.2901C17.7199 18.4102 17.6737 18.5254 17.5914 18.6103C17.5091 18.6952 17.3975 18.7429 17.2811 18.7429C17.1648 18.7429 17.0532 18.6952 16.9709 18.6103C16.8886 18.5254 16.8424 18.4102 16.8424 18.2901V10.529H16.804C16.7073 10.5271 16.6106 10.5319 16.5145 10.5432C16.3113 10.6472 16.0923 10.7147 15.8671 10.7428C15.526 10.9181 15.2385 11.1872 15.0363 11.5206C14.8342 11.854 14.7253 12.2385 14.7217 12.6318V17.3294C14.7217 18.4956 15.6739 19.4341 16.804 19.4341H17.7583C18.8884 19.4341 19.8406 18.4956 19.8406 17.3294Z"
                                            fill="white"
                                        />
                                    </svg>
                                </Link>
                            </div>
                            <div className="logo__text">
                                <Link className="strekoza" to="/">
                                    С<span className="trek">трек</span>оза
                                </Link>
                            </div>
                        </div>
                        <div className="header-menu">
                            <nav className="nav">
                                <ul className="nav__list">
                                    {menuLink.map((item, i) => {
                                        const isActive = location.pathname === item.url
                                        return (
                                            <li key={i} className="nav__item">
                                                <Link
                                                    to={item.url}
                                                    className={
                                                        "js-nav-link nav__link " +
                                                        (isActive ? "active" : "")
                                                    }
                                                    onClick={() => yandexMetric(item.reach)}
                                                >
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <ul className="">
                                    <li className="auth__item">
                                        <a
                                            className="auth-header-btn"
                                            href="http://deliverycrm.ru/login"
                                            onClick={() => yandexMetric("mainAuth")}
                                            target="_blank"
                                        >
                                            Войти
                                        </a>
                                    </li>
                                </ul>
                                <ul className="">
                                    <li className="demo__item">
                                        <a
                                            className="demo__bg"
                                            href="http://deliverycrm.ru/register"
                                            onClick={() => yandexMetric("mainregister")}
                                            target="_blank"
                                        >
                                            Регистрация
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
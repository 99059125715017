import React from "react";
import TgIcon from "../images/tg-icon.png";
import VkIcon from "../images/vk-icon.jpg";
import FbIcon from "../images/fb-icon.jpg";
import InstIcon from "../images/inst-icon.jpg";
import SkLogo from "../images/sk_logo.png";
import {Link} from "gatsby";

export function Footer() {
    return (
        <footer className="footer-bg">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-line"></div>
                    <div className="footer-tel">
                        <ul>
                            <li className="footer__social">
                                <a href="https://vk.com/strekozaai" target="_blank" rel="noopener noreferrer">
                                    <img className="footer__social_icon" src={VkIcon} alt="Вконтакте"/>
                                </a>
                            </li>
                        </ul>
                        <div className="logo-sk">
                            <a href="https://www.sk.ru" target="_blank">
                                <img className="" src={SkLogo} alt="sk logo"/>
                            </a>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <span className="footer__sup">почтатех <span className="footer__member">Победитель корпоративного акселератора Почты России</span></span>
                        <span className="footer__date">Стрекоза {new Date().getFullYear()}г.</span>
                        <div className="footer__rekv">
                            <span className="footer__rekv_weight">ООО "СТРЕКОЗА"</span>
                            <span className="footer__rekv_weight">ОГРН 1217700197934</span>
                            <span className="footer__rekv_weight">ИНН 7716956287</span>
                        </div>
                        <div className="nav__footer_support font_light">
                            <Link to="/policy">Положение о конфиденциальности персональных данных</Link>
                            <br/>
                            <Link to="/user-agreement">Пользовательское соглашение</Link>
                        </div>
                        <div className="nav__footer_support font_light">
                            <a href="mailto:support@strekoza.ai">support@strekoza.ai</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "../styles/reset.css"
import "../styles/fonts.css"
import "../styles/site.css"
import "../styles/media.css"

import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql, Link, StaticQuery } from "gatsby"
import BurgerMenu from "react-burger-menu"
import Cookies from 'universal-cookie';
import { Footer } from "./Footer";
import { Header } from "./Header";
import { yandexMetric } from "./Functions/yandexMetric";


const Menu = BurgerMenu["slide"]


class Layout extends Component {
  constructor(props) {
    const query = new URLSearchParams(props.location.search)
    const promoCode = query.get('pcode')
    const cookies = new Cookies()

    if (promoCode) {
      cookies.set('pcode', promoCode, { path: '/', maxAge: 60 * 60 * 24 * 30 });
    }

    super(props)
    this.state = {
      isScrolled: false,
      menuIsOpened: false,
      menuItem: props.location.pathname === "/",
    }

    this.onScroll = this.onScroll.bind(this)
  }

  onScroll() {
    const isScrolled = window.scrollY > 200

    this.setState({ isScrolled })
  }

  componentDidMount() {

    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  menuCloseItem = () => {
    this.setState({
      menuIsOpened: false,
    })
  }

  render() {

    const { children, location } = this.props


    const mobileMenuLink = [
      { url: "/", name: "Главная", reach: "mainmenumobile", id: 1 },
      { url: "/scoring/", name: "Скоринг", reach: "scoringmobile", id: 3 },
      { url: "/blog/", name: "Блог", reach: "blogmobile", id: 4 },
    ]


    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
              site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div id="outer-container" className={this.state.isScrolled ? "scrolled" : ""}>
            <Menu
              pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
              right
              htmlClassName={"menu-opened"}
              onStateChange={(state) => this.setState({ menuIsOpened: state.isOpen })}
              isOpen={this.state.menuIsOpened}
            >
              <h2>Меню</h2>
              {mobileMenuLink.map((item, i) => {
                const isActive = location.pathname === item.url

                return (
                  <Link key={i} to={item.url} className={isActive ? "active" : ""} onClick={() => yandexMetric(item.reach)}>{item.name}</Link>
                )
              })}
              <a
                  className="bm-item demo"
                  href="http://deliverycrm.ru/register"
                  onClick={() => {
                    this.setState({ menuIsOpened: false })
                    yandexMetric("mobileregister")
                  }}
                  target="_blank"
              >
                Регистрация
              </a>
            </Menu>

            <div id="page-wrap">
              <Header location={location} />
              <main id="content" className="main-section">{children}</main>
              <Footer />
            </div>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
